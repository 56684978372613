import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { FeedbackLoader } from './shared/loaders';

const RateActivity = (props) => {
  const { activity } = props;
  const [ratings, setRatings] = useState({
    valOne: '0',
    valTwo: '0',
    valThree: ' 0',
  });

  const validationSchema = yup.object().shape({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const successMsg = {
    heading: 'Success!',
    message: "Thank you. We've got your details and will be in touch.",
  };

  const [submitting, setSubmitting] = useState(false);
  const [formSuccess, showSuccess] = useState(false);
  const [formError, showError] = useState(false);

  // const jumpRef = useRef();
  const reRef = useRef();

  const onSubmit = async (data) => {
    setSubmitting(true);
    showError(false);
    const token = await reRef.current.executeAsync();
    data.token = token;
    data.activity = activity;
    data.ratingOne = ratings.valOne;
    data.ratingTwo = ratings.valTwo;
    data.ratingThree = ratings.valThree;
    reRef.current.reset();

    const response = await fetch('/.netlify/functions/sendRateActivity', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify({ data }),
    });

    setTimeout(() => {
      if (response.status !== 200) {
        setSubmitting(false);
        showError(true);
        // jumpRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      } else {
        setSubmitting(false);
        showSuccess(true);
        reset();
        setRatings({ valOne: '0', valTwo: '0', valThree: ' 0' });
        // jumpRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }, 1500);
  };

  return (
    <section className="aq-feature-bg py-10 bg-green text-white">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-white">What did you think?</h2>
              <p>
                We are aiming for all 5 stars, but would love you to score us
                honestly so we can use this to improve our activities.
              </p>
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '100px',
                  height: '135px',
                  width: '135px',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Stars/Star-2.svg"
                  alt="Illustration of a star"
                />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 d-flex justify-content-center">
              <div className="star-rating" data-value={ratings.valOne}>
                <p className="label text-center">Length of activity</p>
                <div className="stars d-flex mb-6">
                  <div
                    className="star"
                    data-score="1"
                    onClick={() => setRatings({ ...ratings, valOne: '1' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="2"
                    onClick={() => setRatings({ ...ratings, valOne: '2' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="3"
                    onClick={() => setRatings({ ...ratings, valOne: '3' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="4"
                    onClick={() => setRatings({ ...ratings, valOne: '4' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="5"
                    onClick={() => setRatings({ ...ratings, valOne: '5' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
              <div className="star-rating" data-value={ratings.valTwo}>
                <p className="label text-center">Enjoyment of activity</p>

                <div className="stars d-flex mb-6">
                  <div
                    className="star"
                    data-score="1"
                    onClick={() => setRatings({ ...ratings, valTwo: '1' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="2"
                    onClick={() => setRatings({ ...ratings, valTwo: '2' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="3"
                    onClick={() => setRatings({ ...ratings, valTwo: '3' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="4"
                    onClick={() => setRatings({ ...ratings, valTwo: '4' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="5"
                    onClick={() => setRatings({ ...ratings, valTwo: '5' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
              <div className="star-rating" data-value={ratings.valThree}>
                <p className="label text-center">Overall activity rating</p>
                <div className="stars d-flex mb-6">
                  <div
                    className="star"
                    data-score="1"
                    onClick={() => setRatings({ ...ratings, valThree: '1' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="2"
                    onClick={() => setRatings({ ...ratings, valThree: '2' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="3"
                    onClick={() => setRatings({ ...ratings, valThree: '3' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="4"
                    onClick={() => setRatings({ ...ratings, valThree: '4' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                  <div
                    className="star"
                    data-score="5"
                    onClick={() => setRatings({ ...ratings, valThree: '5' })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.447"
                      height="43.223"
                      viewBox="0 0 45.447 43.223"
                    >
                      <path
                        data-name="Path 139"
                        d="M19.5,0l6.026,12.21L39,14.168l-9.75,9.5,2.3,13.42L19.5,30.755,7.448,37.091l2.3-13.42L0,14.168,13.474,12.21Z"
                        transform="translate(3.223 3.389)"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="3"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-lg-12">
                {!formSuccess && (
                  <button
                    className="btn btn-light-blue mt-3"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </button>
                )}
                {submitting && <FeedbackLoader />}
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  size="invisible"
                  ref={reRef}
                />
                <br />
                {formSuccess && (
                  <p className="text-white pt-2">
                    Feedback received - Thanks for letting us know!
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RateActivity;
