import React from 'react';

import Layout from '../../../components/layout';
import Seo from '../../../components/Seo';
import ActivityBreadCrumb from '../../../components/ActivityBreadCrumbs';
import RateActivity from '../../../components/RateActivity';

const SpotTheDifference = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Spot the difference',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pb-8 pt-8 bg-grey">
      <ActivityBreadCrumb location={location} />
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pt-5 pb-lg-0">
              <h1 className="h1-two-line-v2 purple mb-12 mb-lg-16 mt-10">
                <span>Spot the</span>
                <br />
                <span>difference </span>
              </h1>
              <div
                style={{
                  position: 'absolute',
                  top: '160px',
                  left: '430px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(180deg)',
                  zIndex: '10',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Arrows/Arrow-3.svg"
                  alt="Illustration of an arrow"
                />
              </div>
              <div className="ml-0 ml-xl-15">
                <h3>
                  Can you spot the water saving differences in the two pictures?
                  See how many times you can circle Isla, Nero and Kai being
                  very mindful with their water in one picture, and very
                  wasteful in the other.
                </h3>
                <p>
                  Can you see which picture they are using water in a much
                  better way? There are lots of changes we can make at home to
                  use less water, how many changes can you make at home?
                </p>
                <div style={{ position: 'relative' }}>
                  <a
                    href="https://cdn.southeastwater.co.uk/aquasmart/mission-1/SEW-Spot-the-difference-v2.pdf"
                    target="_blank"
                    className="btn btn-light-blue mt-2 mr-sm-2 mt-sm-0"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                  <div
                    style={{
                      position: 'absolute',
                      top: '-80px',
                      left: '200px',
                      height: '135px',
                      width: '135px',
                      transform: 'rotate(180deg)',
                    }}
                    className="d-none d-lg-block"
                  >
                    <img
                      src="/images/Doodles/Arrows/Arrow-1.svg"
                      alt="Illustration of an arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <img
                src="/images/Activities/MissionOne/Aqua-Spot-the-Difference.svg"
                className="w-100"
                alt="Spot the difference "
              />
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-6 col-lg-6">
              <div className="text-center">
                <a
                  href="https://cdn.southeastwater.co.uk/aquasmart/mission-1/SEW-Spot-the-difference-Answers.pdf"
                  target="_blank"
                  className="btn btn-light-blue mt-2 mr-sm-2 mt-sm-0"
                  rel="noreferrer"
                >
                  Answers
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <RateActivity activity="Spot the difference" />
  </Layout>
);

export default SpotTheDifference;
